<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('graduate')"
                        :isFilter="false"
                        :is-back-button="true"
                        :back-button="backButton"
                        :selected-menu="selectedMenu" />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('graduate')"
                              :isFilter="false"
                              :is-back-button="true"
                              :back-button="backButton"
                              :selected-menu="selectedMenu" />
            </template>
            <div class="d-flex flex-column flex-lg-row">
                <div class="mr-lg-5">
                    <info-page :personalInfo="responsePersonalData" :editMode="true" />
                </div>
                <div class="mr-lg-5">
                    <menu-page v-model="selectedMenu" :graduateData="responseData" :menu-data="menuData" />
                    <b-dropdown :text="computeDropdownMenuName" block split split-variant="outline-primary"
                                variant="primary" class="d-block d-lg-none secondary-border-lighter btn-outline-secondary mb-3">
                        <template v-for="menu in menuData">
                            <b-dropdown-item :key="'dropdownMenu' + menu.slug" @click="selectedMenu = menu.slug"
                                             v-if="(menu.permission == '' || checkPermission(menu.permission)) && menu.show">
                                {{ menu.title.toUpper() }}
                            </b-dropdown-item>
                        </template>
                    </b-dropdown>
                </div>
                <div class="flex-grow-1 w-100 w-lg-0" v-if="responseData">
                    <component :is="selectedComponent" :contactsInformationData="responseContactsData" :degreeInformation="responseDegreeData" :contactInfo="responseContactData" :workHistoryInfo="responseWorkHistoryData" :otherInfo="responseOtherData" :communicationInfo="responseCommunicationData" :educationInfo="responseEducationData" :workingInfo="responseWorkingData" :personalInfo="responsePersonalData" :graduateData="responseData" @updateClick="callUpdateModal" @updateSuccess="closeModal"></component>
                </div>
            </div>
            <CommonModal ref="updateModal" size="xl">
                <template v-slot:CommonModalTitle>
                    {{ updateModalTitle.toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <component :is="updateNavi" v-if="updateNavi" :contactsInformationData="responseContactsData" :degreeInformation="responseDegreeData" :contactInfo="responseContactData" :workHistoryInfo="responseWorkHistoryData" :otherInfo="responseOtherData" :communicationInfo="responseCommunicationData" :educationInfo="responseEducationData" :workingInfo="responseWorkingData" :personalInfo="responsePersonalData" :graduateData="responseData" :updateData="updateData" @updateSuccess="closeModal" />
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

// Components
import CommonModal from "@/components/elements/CommonModal";

//Pages
import GraduateInfo from "./actionTabs/GraduateInfo"
import ContactConfirmations from "./actionTabs/ContactConfirmations"
import EducationInfo from "./actionTabs/EducationInfo"
import JobInformation from "./actionTabs/JobInformation"
import Other from "./actionTabs/Other"
import PersonalInfo from "./actionTabs/PersonalInfo"
import WorkHistoryAdd from "./actionTabs/WorkHistoryAdd"
import WorkHistoryEdit from "./actionTabs/WorkHistoryEdit"
import MeetingsAdd from "./actionTabs/MeetingsAdd"
import MeetingsEdit from "./actionTabs/MeetingsEdit"
import EducationInfoAdd from "./actionTabs/EducationInfoAdd"
import EducationInfoEdit from "./actionTabs/EducationInfoEdit"

import GraduateInfoTab from "./tabs/GraduateInfo"
import PersonalInfoTab from "./tabs/PersonalInfo"
import JobInformationTab from "./tabs/JobInformation"
import EducationInfoTab from "./tabs/EducationInfo"
import ContactConfirmationsTab from "./tabs/ContactConfirmations"
import OtherTab from "./tabs/Other"
import WorkHistoryTab from "./tabs/WorkHistory"
import MeetingsTab from "./tabs/Meetings"

import InfoPage from "./InfoPage"
import MenuPage from "./MenuPage"
import menuData from "@/modules/graduates/data/Menus"

// Services
import AlumniService from '@/services/AlumniService';

// Others

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        CommonModal,

        GraduateInfoTab,
        PersonalInfoTab,
        JobInformationTab,
        EducationInfoTab,
        ContactConfirmationsTab,
        OtherTab,
        WorkHistoryTab,
        MeetingsTab,

        GraduateInfo,
        ContactConfirmations,
        EducationInfo,
        JobInformation,
        Other,
        PersonalInfo,
        WorkHistoryAdd,
        WorkHistoryEdit,
        MeetingsAdd,
        MeetingsEdit,
        EducationInfoAdd,
        EducationInfoEdit,

        InfoPage,
        MenuPage,
    },

    metaInfo() {
        return {
            title: this.$t('graduate')
        }
    },

    data() {
        return {
            responseData: null,
            responsePersonalData: null,
            responseWorkingData: null,
            responseEducationData: null,
            responseCommunicationData: null,
            responseOtherData: null,
            responseWorkHistoryData: null,
            responseContactData: null,
            responseDegreeData: null,
            responseContactsData: null,
            studentId: null,
            pageTitle: this.$t('graduate_info'),
            componentNavi: 'GraduateInfoTab',
            microTabs: [
                {
                    name: this.$t('graduate_info'),
                    component: 'GraduateInfoTab'
                },
                {
                    name: this.$t('work_history'),
                    component: 'WorkingHistoryTab'
                },
                {
                    name: this.$t('meetings'),
                    component: 'MeetingsTab'
                }
            ],
            updateModalTitle: '',
            updateNavi: null,
            updateData: null,

            selectedMenu: 'graduate_info',
            selectedComponent: 'GraduateInfoTab',
            menuData: null,
        }
    },
    async created() {
        this.studentId = this.$route.params.id;
        await this.getData(), this.PersonalData(), this.WorkingData(), this.EducationData(), this.CommunicationData(), this.OtherData(), 
        this.WorkHistoryData(), this.ContactData(), this.degreeInformationData(), this.contactsInformationData()
        this.menuData = menuData(null, this.responseData)
    },
    methods: {
        tabNavi(elem){
            this.pageTitle = elem.name
            this.componentNavi = elem.component
        },

        callUpdateModal(data,updateData){
            this.updateModalTitle = this.$t(data[data.length - 1])
            this.updateNavi = this.createTabName(data)
            this.updateData = updateData

            this.$refs.updateModal.$refs.commonModal.show()
        },

        closeModal(){
            this.$refs.updateModal.$refs.commonModal.hide()
            this.PersonalData()
            this.WorkingData()
            this.CommunicationData()
            this.OtherData()
            this.degreeInformationData()
            this.EducationData()
            this.WorkHistoryData()
            this.contactsInformationData()
        },

        createTabName(str){
            let response = ''
            let dataArr = str[0].split('_')

            dataArr.forEach(itm => {
                response += itm.charAt(0).toUpperCase() + itm.slice(1)
            })

            if(str.length > 1) {
                response += str[1].charAt(0).toUpperCase() + str[1].slice(1)
            }

            return response
        },

        createComponentTabName(str){
            return this.createTabName([str])+'Tab'
        },

        getData(){
            return AlumniService.get(this.studentId)
                .then(response => {
                    this.responseData  = response.data.data;
                })
                .catch(e => {
                    this.$router.push('/404')
                })
        },
        PersonalData(){
            return AlumniService.PersonalInfo(this.studentId)
                .then(response => {
                    this.responsePersonalData  = response.data.data;
                })
                .catch(e => {
                    this.$router.push('/404')
                })
        },
        WorkingData(){
            return AlumniService.WorkingInfo(this.studentId)
                .then(response => {
                    this.responseWorkingData  = response.data.data;
                })
                .catch(e => {
                    this.$router.push('/404')
                })
        },
        EducationData(){
            return AlumniService.EducationInfo(this.studentId)
                .then(response => {
                    this.responseEducationData  = response.data.data;
                })
                .catch(e => {
                    this.$router.push('/404')
                })
        },
        CommunicationData(){
            return AlumniService.CommunicationInfo(this.studentId)
                .then(response => {
                    this.responseCommunicationData  = response.data.data;
                })
                .catch(e => {
                    this.$router.push('/404')
                })
        },
        OtherData(){
            return AlumniService.OtherInfo(this.studentId)
                .then(response => {
                    this.responseOtherData  = response.data.data;
                })
                .catch(e => {
                    this.$router.push('/404')
                })
        },
        WorkHistoryData(){
            return AlumniService.WorkHistoryInfo(this.studentId)
                .then(response => {
                    this.responseWorkHistoryData  = response.data.data;
                })
                .catch(e => {
                    this.$router.push('/404')
                })
        },
        ContactData(){
            return AlumniService.ContactInfo(this.studentId)
                .then(response => {
                    this.responseContactData  = response.data.data;
                })
                .catch(e => {
                    this.$router.push('/404')
                })
        },
        degreeInformationData(){
            return AlumniService.degreeInformation(this.studentId)
                .then(response => {
                    this.responseDegreeData  = response.data.data;
                })
                .catch(e => {
                    this.$router.push('/404')
                })
        },
        contactsInformationData(){
            return AlumniService.contactsInformation(this.studentId)
                .then(response => {
                    this.responseContactsData  = response.data.data;
                })
                .catch(e => {
                    this.$router.push('/404')
                })
        },
        backButton() {
            this.$router.push('/graduates')
        },
    },

    computed: {
        computeDropdownMenuName: {
            get() {
                let selected = (this.menuData || []).find(item => item.slug == this.selectedMenu)
                return selected ? this.toUpperCase(selected.title) : '';
            },
            set() {

            }
        },
    },

    watch: {
        selectedMenu: {
            handler(newValue){
                this.selectedComponent = this.createComponentTabName(newValue)
            }
        }
    }
}
</script>

