<template>
    <div>
        <ValidationObserver ref="formValidate">
            <b-row>
                <b-col md="4">
                    <ValidationProvider name="start_date" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('work_start_date')">
                            <select-date v-model="formData.start_date"
                                         :validationError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="end_date" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('work_leave_date')">
                            <select-date v-model="formData.end_date"
                                         :validationError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="company" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('corporation')">
                            <b-form-input v-model="formData.company"
                                          :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="department" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('corp_department')">
                            <b-form-input v-model="formData.department"
                                          :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="contract_type" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('working_type')">
                            <b-form-input v-model="formData.contract_type"
                                          :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="workspace_status" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('working_tract')">
                            <b-form-input v-model="formData.workspace_status"
                                          :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" lg="12" class="d-flex justify-content-end">
                    <b-button variant="primary" @click="saveForm">{{ $t('save') }}</b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>
<script>
// Template

// Components

//Pages

// Services
import AlumniWorkService from '@/services/AlumniWorkService';

// Others
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
    props: {
        workHistoryInfo: {
            type: Object,
            default: {}
        },
        updateData: {
            type: Object,
            default: {}
        },
    },

    components: {
        ValidationObserver,
        ValidationProvider
    },

    data() {
        return {
            formData: {},
            studentId: null
        }
    },
    created() {
        this.setData()
        this.studentId = this.$route.params.id
    },
    methods: {
        setData(){
            this.formData = this.updateData
        },

        async saveForm() {
            const isValid = await this.$refs.formValidate.validate();
                if (isValid) {

                    let formData = {
                    ...this.formData,
                    student_id: this.studentId
                    
                }
                AlumniWorkService.update(this.formData.id, formData)
                                 .then((response) => {
                                     this.formLoading=false;
                                     this.$toast.success(this.$t("api." + response.data.message));
                                     this.$emit('updateSuccess')
                                 })
                                 .catch((error) => {
                                     this.showErrors(error, this.$refs.formModalValidate)
                                 })
                }
        }
    }
}
</script>

