<template>
    <div>
        <ValidationObserver ref="formValidate">
            <b-row>
                <b-col md="4">
                    <ValidationProvider name="date" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('registration_date_just')">
                            <select-date v-model="formData.date"
                                         :validationError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="status" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('meeting_status')">
                            <multi-selectbox
                                :multiple="false"
                                :validate-error="errors[0]"
                                :options="meetingStatusOptions"
                                v-model="formData.status" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="note" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('meeting_note')">
                            <b-form-textarea v-model="formData.note"
                                             :class="errors[0] ? 'is-invalid':''"
                                             rows="6"
                            />
                            <span class="invalid-feedback-custom" v-show="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" lg="12" class="d-flex justify-content-end">
                    <b-button variant="primary" @click="saveForm">{{ $t('save') }}</b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>
<script>
// Template

// Components

//Pages

// Services
import AlumniContactService from '@/services/AlumniContactService';

// Others
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
    props: {
        contactsInformationData: {
            type: Array,
            default: []
        },
    },

    components: {
        ValidationObserver,
        ValidationProvider,
    },

    data() {
        return {
            formData: {},
            meetingStatusOptions: [
                {
                    text: 'BARC',
                    value: 'BARC'
                },
                {
                    text: 'Beklemede',
                    value: 'Beklemede'
                },
                {
                    text: 'Bilgilendirme istedi',
                    value: 'Bilgilendirme istedi'
                },
                {
                    text: 'İletişim İzni Yok',
                    value: 'İletişim İzni Yok'
                },
                {
                    text: 'İlgilenmiyor',
                    value: 'İlgilenmiyor'
                },
                {
                    text: 'İptal',
                    value: 'İptal'
                },
                {
                    text: 'Kullanılmayan Numara',
                    value: 'Kullanılmayan Numara'
                },
                {
                    text: 'Olumlu',
                    value: 'Olumlu'
                }
            ]
        }
    },
    created() {
        this.setData()
        this.studentId = this.$route.params.id
    },
    methods: {
        setData() {
            this.formData = {}
        },
        async saveForm(){
            const isValid = await this.$refs.formValidate.validate();
            if (isValid) {

                let formData = {
                    ...this.formData,
                    student_id: this.studentId
                }

                AlumniContactService.store(formData)
                .then(response => {
                    this.$emit('updateSuccess')
                    this.$toast.success(this.$t('api.' + response.data.message));
				}).catch(e => {
					this.showErrors(e, this.$refs.formModalValidate)
					}).finally(() => {
						this.formProcess = false
					})
            }
        },
    }
}
</script>

