<template>
    <div>
        <ValidationObserver ref="formValidate">
            <b-row>
                <b-col md="4">
                    <ValidationProvider name="level" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('education_status')">
                            <parameter-selectbox v-model="formData.level"
                                                 code="education_statuses"
                                                 sort="id"
                                                 :validate-error="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="8">
                    <label>{{ $t('education_information') }}</label>
                    <div class="border rounded p-4">
                        <div v-if="formData.level==null">
                            <b-form-group :title="$t('school')+' / '+$t('university')">
                                <div class="label-as-input">-</div>
                            </b-form-group>
                        </div>
                        <div v-else>
                            <div v-if="formData.level && formData.level=='high_school'">
                                <ValidationProvider name="school" rules="required" v-slot="{errors}">
                                    <b-form-group :label="$t('school')">
                                        <school-selectbox-auto-complate v-model="formData.school"
                                                                        :validate-error="errors[0]" />
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div v-else>
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <ValidationProvider name="university_type" rules="required" v-slot="{errors}">
                                            <b-form-group :label="$t('university_type')">
                                                <parameter-selectbox v-model="university_type"
                                                                     :validateError="errors[0]"
                                                                     code="university_types"
                                                />
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col cols="12" md="6">
                                        <ValidationProvider name="university_country_id" rules="required" v-slot="{errors}">
                                            <b-form-group :label="$t('country')">
                                                <country-selectbox v-model="university_country_id"
                                                                   :validateError="errors[0]"
                                                />
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col cols="12">
                                        <ValidationProvider name="school" rules="required" v-slot="{errors}">
                                            <b-form-group :label="$t('university')">
                                                <university-selectbox v-model="formData.school"
                                                                      :type="university_type"
                                                                      :is-type-required="true"
                                                                      :countryId="university_country_id"
                                                                      :is-country-required="true"
                                                                      :validate-error="errors[0]"
                                                />
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col cols="12">
                                        <ValidationProvider name="program" rules="required" v-slot="{errors}">
                                            <b-form-group :label="$t('program')">
                                                <b-form-input v-model="formData.program"
                                                              :state="errors[0] ? false : null" />
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                        <b-row>
                            <b-col cols="12" md="4">
                                <ValidationProvider name="graduate_date" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('graduate_date')">
                                        <select-date v-model="formData.graduate_date"
                                                     :validationError="errors[0]"
                                        />
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="12" md="4">
                                <ValidationProvider name="diploma_number" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('diploma_number')">
                                        <b-form-input v-model="formData.diploma_number"
                                                      :state="errors[0] ? false : null"
                                                      type="number"
                                        />
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="12" md="4">
                                <ValidationProvider name="diploma_point" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('diploma_point')">
                                        <b-form-input v-model="formData.diploma_point"
                                                      :state="errors[0] ? false : null"
                                                      type="number"
                                                      step="0.01"
                                        />
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </div>
                </b-col>
                <b-col cols="12" class="d-flex justify-content-end">
                    <b-button class="mt-4" variant="primary" @click="saveForm">{{ $t('save') }}</b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>
<script>
// Template

// Components
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox"
import SchoolSelectboxAutoComplate from "@/components/interactive-fields/SchoolSelectboxAutoComplate"
import CountrySelectbox from "@/components/interactive-fields/CountrySelectbox"
import UniversitySelectbox from "@/components/interactive-fields/UniversitySelectbox"

//Pages

// Services
import AlumniEducationService from '@/services/AlumniEducationService';
import AlumniService from '@/services/AlumniService';

// Others
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
    props: {
        degreeInformation: {
            type: Object,
            default: {}
        },
        updateData: {
            type: Object,
            default: {}
        },
    },

    components: {
        ValidationObserver,
        ValidationProvider,

        ParameterSelectbox,
        SchoolSelectboxAutoComplate,
        CountrySelectbox,
        UniversitySelectbox
    },

    data() {
        return {
            formData: {
                level: null,
                diploma_point: null,
            },
            studentId: null,
            university_type: null,
            university_country_id: null
        }
    },
    created() {
        this.setData()
        this.studentId = this.$route.params.id
    },
    methods: {
        setData() {
            this.formData = this.updateData
        },
        EducationData(){
            return AlumniService.EducationInfo(this.studentId)
                .then(response => {
                    let data = response.data.data;
                                           this.formData = {
                                               level: data.level,
                                               diploma_point: data.diploma_point,
                                           }
                })
                .catch(e => {
                    this.$router.push('/404')
                })
        },
        async saveForm() {
            const isValid = await this.$refs.formValidate.validate();
                if (isValid) {

                    let formData = {
                    ...this.formData,
                    student_id: this.studentId
                    
                }
                    AlumniEducationService.update(this.formData.id, formData)
                                 .then((response) => {
                                     this.formLoading=false;
                                     this.$toast.success(this.$t("api." + response.data.message));
                                     this.$emit('updateSuccess')
                                 })
                                 .catch((error) => {
                                     this.showErrors(error, this.$refs.formModalValidate)
                                 })
                }
        }
    }
}
</script>

