<template>
    <div>
        <b-row>
            <b-col cols="12" lg="12">
                <b-card :header="$t('other')" class="soft mb-5">
                    <b-row>
                        <b-col cols="12" lg="4">
                            <div class="label-as-input-div">
                                <label>{{ $t('graduate_card_status') }}</label>
                                <div v-if="otherInfo && otherInfo.alumni_card_status && (otherInfo.alumni_card_status === 'card_created')">{{ $t('card_created') }}</div>
                                <div v-else-if="otherInfo && otherInfo.alumni_card_status && (otherInfo.alumni_card_status === 'card_not_created')">{{ $t('card_not_created') }}</div>
                                <div v-else>{{ otherInfo.alumni_card_status }}</div>
                            </div>
                        </b-col>

                        <b-col cols="12" lg="4">
                            <div class="label-as-input-div">
                                <label>{{ $t('graduate_email_status') }}</label>
                                <div>{{ otherInfo.alumni_email_status }}</div>
                            </div>
                        </b-col>

                        <b-col cols="12" lg="4">
                            <div class="label-as-input-div">
                                <label>{{ $t('death_status') }}</label>
                                <div v-if="otherInfo && otherInfo.death_status && (otherInfo.death_status === 'in_life')">{{ $t('in_life') }}</div>
                                <div v-if="otherInfo && otherInfo.death_status && (otherInfo.death_status === 'died')">{{ $t('died') }}</div>

                                <div v-else>{{ otherInfo.death_status }}</div>
                            </div>
                        </b-col>

                        <b-col cols="12" lg="4">
                            <div class="label-as-input-div">
                                <label>{{ $t('kvkk_code') }}</label>
                                <div>{{ otherInfo.kvkk_code }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="12">
                            <b-button class="mt-4" variant="primary" @click="$emit('updateClick',['other'])">{{ $t('edit') }}</b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
// Template

// Components

//Pages

// Services

// Others

export default {
    props: {
        otherInfo: {
            type: Object,
            default: {}
        },
    },

    components: {

    },

    data() {
        return {

        }
    },
    created() {

    },
    methods: {

    }
}
</script>

