<template>
    <div>
        <ValidationObserver ref="formValidate">
            <b-row>
                <b-col md="3">
                    <ValidationProvider name="alumni_card_status" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('graduate_card_status')">
                            <multi-selectbox
                                :multiple="false"
                                :options="cardStatusOptions"
                                v-model="formData.alumni_card_status" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="3">
                    <ValidationProvider name="alumni_email_status" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('graduate_email_status')">
                            <b-form-input v-model="formData.alumni_email_status"
                                          :placeholder="$t('graduate_email_status')"
                                          :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="3">
                    <ValidationProvider name="death_status" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('death_status')">
                            <multi-selectbox
                                :multiple="false"
                                :options="deathOptions"
                                v-model="formData.death_status" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="3">
                    <ValidationProvider name="kvkk_code" rules="" v-slot="{ errors }">
                        <b-form-group :label="$t('kvkk_code')">
                            <b-form-input v-model="formData.kvkk_code"
                                          :placeholder="$t('kvkk_code')"
                                          :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" lg="12" class="d-flex justify-content-end">
                    <b-button class="mr-2" variant="outline-primary" @click="sendCode">{{ $t('kvkk_code_send') }}</b-button>
                    <b-button variant="primary" @click="saveForm">{{ $t('save') }}</b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>
<script>
// Template

// Components

//Pages

// Services
import AlumniService from '@/services/AlumniService';

// Others
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
    props: {
        otherInfo: {
            type: Object,
            default: {}
        },
    },

    components: {
        ValidationObserver,
        ValidationProvider
    },

    data() {
        return {
            formData: {},
            cardStatusOptions: [
                {
                    text: this.$t('card_created'),
                    value: 'card_created'
                },
                {
                    text: this.$t('card_not_created'),
                    value: 'card_not_created'
                }
            ],
            deathOptions: [
                {
                    text: this.$t('in_life'),
                    value: 'in_life'
                },
                {
                    text: this.$t('died'),
                    value: 'died'
                }
            ]
        }
    },
    created() {
        this.setData()
        this.studentId = this.$route.params.id
    },
    methods: {
        setData(){
            this.formData = {
                alumni_card_status: this.otherInfo.alumni_card_status,
                alumni_email_status: this.otherInfo.alumni_email_status,
                death_status: this.otherInfo.death_status,
                kvkk_code: this.otherInfo.kvkk_code

            }
        },

        async saveForm(){
            const isValid = await this.$refs.formValidate.validate();
            if (isValid) {

                let formData = {
                    ...this.formData
                }

                AlumniService.otherInfoUpdate(this.studentId, formData)
                .then(response => {
                    this.$emit('updateSuccess')
                    this.$toast.success(this.$t('api.' + response.data.message));
				}).catch(e => {
					this.showErrors(e, this.$refs.formModalValidate)
					}).finally(() => {
						this.formProcess = false
					})
            }
        },

        sendCode(){
            this.$toast.success(this.$t('successfully_saved'))
        }
    }
}
</script>

