<template>
    <div>
        <ValidationObserver ref="formValidate">
            <b-row>
                <b-col md="4">
                    <ValidationProvider name="working_status" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('work_status')">
                            <multi-selectbox v-model="formData.working_status"
                                             :multiple="false"
                                             :options="workStatusOptions"
                                             :validate-error="errors[0]">
                            </multi-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="not_working_reason" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('not_working_reason')">
                            <multi-selectbox v-model="formData.not_working_reason"
                                             :multiple="false"
                                             :options="notWorkStatusOptions"
                                             :validate-error="errors[0]">
                            </multi-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="working_organisation" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('job_corporation')">
                            <b-form-input v-model="formData.working_organisation"
                                          :placeholder="$t('job_corporation')"
                                          :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="working_sector" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('job_sector')">
                            <multi-selectbox v-model="formData.working_sector"
                                             :multiple="false"
                                             :options="sectorOptions"
                                             :validate-error="errors[0]">
                            </multi-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="working_position" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('positional_status')">
                            <multi-selectbox v-model="formData.working_position"
                                             :multiple="false"
                                             :options="positionalOptions"
                                             :validate-error="errors[0]">
                            </multi-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="working_position_detail" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('position_detail')">
                            <b-form-input v-model="formData.working_position_detail"
                                          :placeholder="$t('position_detail')"
                                          :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" lg="12" class="d-flex justify-content-end">
                    <b-button variant="primary" @click="saveForm">{{ $t('save') }}</b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>
<script>
// Template

// Components
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox"

//Pages

// Services
import AlumniService from '@/services/AlumniService';

// Others
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
    props: {
        workingInfo: {
            type: Object,
            default: {}
        },
    },

    components: {
        ValidationObserver,
        ValidationProvider,

        MultiSelectbox
    },

    data() {
        return {
            formData: {},
            workStatusOptions: [
                {
                    text: this.$t('working'),
                    value: 'working'
                },
                {
                    text: this.$t('not_working'),
                    value: 'not_working'
                }
            ],
            notWorkStatusOptions: [
                {
                    text: this.$t('not_working_in_military'),
                    value: 'not_working_in_military'
                },
                {
                    text: this.$t('not_working_no_info'),
                    value: 'not_working_no_info'
                },
                {
                    text: this.$t('not_working_other'),
                    value: 'not_working_other'
                },
                {
                    text: this.$t('not_working_education'),
                    value: 'not_working_education'
                },
                {
                    text: this.$t('not_working_marriage'),
                    value: 'not_working_marriage'
                },
                {
                    text: this.$t('not_working_searching_job'),
                    value: 'not_working_searching_job'
                }
            ],
            sectorOptions: [
                {
                    text: 'Bankacılık',
                    value: 'banka'
                },
                {
                    text: 'Yazılım',
                    value: 'Yazılım'
                }
            ],
            positionalOptions: [
                {
                    text: 'Girişimci/İş Yeri Sahibi',
                    value: 'Girişimci/İş Yeri Sahibi'
                },
                {
                    text: 'Yazılım Uzmanı',
                    value: 'Yazılım Uzmanı'
                },
                {
                    text: 'Öğretim Elemanı',
                    value: 'Öğretim Elemanı'
                }
            ],
        }
    },
    created() {
        this.setData()
        this.studentId = this.$route.params.id
    },
    methods: {
        setData(){
            this.formData = {
                working_status: this.workingInfo.working_status,
                not_working_reason: this.workingInfo.not_working_reason,
                working_organisation: this.workingInfo.working_organisation,
                working_sector: this.workingInfo.working_sector,
                working_position: this.workingInfo.working_position,
                working_position_detail: this.workingInfo.working_position_detail
            }
        },
        async saveForm(){
            const isValid = await this.$refs.formValidate.validate();
            if (isValid) {

                let formData = {
                    ...this.formData
                }

                AlumniService.workingUpdate(this.studentId, formData)
                .then(response => {
                    this.$emit('updateSuccess')
                    this.$toast.success(this.$t('api.' + response.data.message));
				}).catch(e => {
					this.showErrors(e, this.$refs.formModalValidate)
					}).finally(() => {
						this.formProcess = false
					})
            }
        },
    }
}
</script>

