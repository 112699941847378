<template>
    <div>
        <b-row>
            <b-col md="6">
                <ValidationObserver ref="formValidate">
                    <ValidationProvider name="want_degree" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('do_you_think_to_do_master_in_corp')">
                            <multi-selectbox v-model="formData.want_degree"
                                :multiple="false"
                                :options="trueOrFalseFormat"
                                :validate-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </ValidationObserver>
            </b-col>
            <b-col cols="6" class="d-flex justify-content-end">
                <b-button class="mt-4" variant="primary" @click="saveForm">{{ $t('save') }}</b-button>
            </b-col>
        </b-row>
    </div>
</template>
<script>
// Template

// Components
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox"

//Pages

// Services
import AlumniService from '@/services/AlumniService';

// Others
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
    props: {
        degreeInformation: {
            type: Object,
            default: {}
        },
    },

    components: {
        ValidationObserver,
        ValidationProvider,
        MultiSelectbox
    },

    data() {
        return {
            formData: {
                want_degree: null
            },
            trueOrFalseFormat: [
                {value: 'e', text: this.$t('yes')},
                {value: 'h', text: this.$t('no')}
            ],
        }
    },
    created() {
        this.setData()
        this.studentId = this.$route.params.id
    },
    methods: {
        setData(){
            this.formData = {
                want_degree: this.degreeInformation.want_degree
            }
            this.formData.want_degree = this.degreeInformation.want_degree == true ? 'e' : 'h'
        },

        async saveForm(){
            const isValid = await this.$refs.formValidate.validate();
            if (isValid) {
                this.formData.want_degree = this.formData.want_degree == 'e' ? 1 : 0
                let formData = {
                    ...this.formData
                }

                AlumniService.degreeInformationUpdate(this.studentId, formData)
                .then(response => {
                    this.$emit('updateSuccess')
                    this.$toast.success(this.$t('api.' + response.data.message));
				}).catch(e => {
					this.showErrors(e, this.$refs.formModalValidate)
					}).finally(() => {
						this.formProcess = false
					})
            }
        },
    }
}
</script>

