<template>
    <div>
        <b-row>
            <b-col cols="12" lg="12">
                <b-card :header="$t('graduate_info')" class="soft mb-5">
                    <div class="accordion" role="tablist">
                        <b-card no-body class="mb-1" v-for="(item, index) in graduateData" :key="index">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                              <b-button block v-b-toggle="'accordion-' + index"  style="background-color: grey; font-size: 10px;">{{ item.faculty_name }} / {{ item.program_name }}</b-button>
                            </b-card-header>
                            <b-collapse :id="'accordion-' + index" visible role="tabpanel">
                              <b-card-body>
                                  <b-row>
                                      <b-col cols="12" lg="3">
                                          <div class="label-as-input-div">
                                              <label>{{ $t('faculty') }}</label>
                                              <div>{{ item.faculty_name }}</div>
                                          </div>
                                      </b-col>
                                      <b-col cols="12" lg="3">
                                          <div class="label-as-input-div">
                                              <label>{{ $t('national_number') }}</label>
                                              <div v-if="personalInfo && personalInfo.national_id">{{ personalInfo.national_id }}</div>
                                          </div>
                                      </b-col>
                                      <b-col cols="12" lg="3">
                                          <div class="label-as-input-div">
                                              <label>{{ $t('graduate_semester') }}</label>
                                              <div>{{ item.graduation_date }}</div>
                                          </div>
                                      </b-col>
                                      <b-col cols="12" lg="3">
                                          <div class="label-as-input-div">
                                              <label>{{ $t('birthdate') }}</label>
                                              <div>{{ graduateData.birthdate }}</div>
                                          </div>
                                      </b-col>
                                      <b-col cols="12" lg="3">
                                          <div class="label-as-input-div">
                                              <label>{{ $t('department') }}</label>
                                              <div>{{ item.department_name }}</div>
                                          </div>
                                      </b-col>
                                      <b-col cols="12" lg="3">
                                          <div class="label-as-input-div">
                                              <label>{{ $t('name') }}</label>
                                              <div v-if="personalInfo && personalInfo.name">{{ personalInfo.name }}</div>
                                          </div>
                                      </b-col>
                                      <b-col cols="12" lg="3">
                                          <div class="label-as-input-div">
                                              <label>{{ $t('graduate_period') }}</label>
                                              <div>{{ item.graduation_semester_name }}</div>
                                          </div>
                                      </b-col>
                                      <b-col cols="12" lg="3">
                                          <div class="label-as-input-div">
                                              <label>{{ $t('last_updated') }}</label>
                                              <div>{{ graduateData.last_updated }}</div>
                                          </div>
                                      </b-col>
                                      <b-col cols="12" lg="3">
                                          <div class="label-as-input-div">
                                              <label>{{ $t('program') }}</label>
                                              <div>{{ item.program_name }}</div>
                                          </div>
                                      </b-col>
                                      <b-col cols="12" lg="3">
                                          <div class="label-as-input-div">
                                              <label>{{ $t('surname') }}</label>
                                              <div v-if="personalInfo && personalInfo.surname">{{ personalInfo.surname }}</div>
                                          </div>
                                      </b-col>
                                      <b-col cols="12" lg="3">
                                          <div class="label-as-input-div">
                                              <label>{{ $t('graduation_date') }}</label>
                                              <div>{{ item.graduation_date }}</div>
                                          </div>
                                      </b-col>
                                      <b-col cols="12" lg="3">
                                          <div class="label-as-input-div">
                                              <label>{{ $t('last_update_datetime') }}</label>
                                              <div>{{ graduateData.last_update_date }}</div>
                                          </div>
                                      </b-col>
                                      <!-- <b-col cols="12" lg="12">
                                          <b-button variant="primary" @click="$emit('updateClick',['graduate_info'])">{{ $t('edit') }}</b-button>
                                      </b-col> -->
                                  </b-row>
                              </b-card-body>
                            </b-collapse>
                        </b-card>
                    </div>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
// Template

// Components

//Pages

// Services

// Others

export default {
    props: {
        graduateData: {
            type: Array,
            default: []
        },
        personalInfo: {
            type: Object,
            default: {}
        }
    },

    components: {
    },

    data() {
        return {

        }
    },
    created() {

    },
    methods: {

    }
}
</script>

