<template>
    <div>
        <b-row>
            <b-col cols="12" lg="12">
                <b-card :header="$t('contact_confirmations')" class="soft mb-5">
                    <b-row>
                        <b-col cols="12" lg="4">
                            <div class="label-as-input-div">
                                <label>{{ $t('approve_sms') }}</label>
                                <div v-if="communicationInfo.sms_approve === false">{{ $t('no') }}</div>
                                <div v-else-if="communicationInfo.sms_approve === true">{{ $t('yes') }}</div>
                                <div v-else>{{ communicationInfo.sms_approve }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <div class="label-as-input-div">
                                <label>{{ $t('approve_email') }}</label>
                                <div v-if="communicationInfo.email_approve === false">{{ $t('no') }}</div>
                                <div v-else-if="communicationInfo.email_approve === true">{{ $t('yes') }}</div>
                                <div v-else>{{ communicationInfo.email_approve }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <div class="label-as-input-div">
                                <label>{{ $t('approve_call') }}</label>
                                <div v-if="communicationInfo.phone_approve === false">{{ $t('no') }}</div>
                                <div v-else-if="communicationInfo.phone_approve === true">{{ $t('yes') }}</div>
                                <div v-else>{{ communicationInfo.phone_approve }}</div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="12">
                            <b-button class="mt-4" variant="primary" @click="$emit('updateClick',['contact_confirmations'])">{{ $t('edit') }}</b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
// Template

// Components

//Pages

// Services

// Others

export default {
    props: {
        communicationInfo: {
            type: Object,
            default: {}
        },
    },

    components: {

    },

    data() {
        return {

        }
    },
    created() {

    },
    methods: {

    }
}
</script>

