<template>
    <div>
        <ValidationObserver ref="formValidate">
            <b-row>
                <b-col md="4">
                    <ValidationProvider name="sms_approve" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('approve_sms')">
                            <multi-selectbox v-model="formData.sms_approve"
                                :multiple="false"
                                :options="trueOrFalseFormat"
                                :validate-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="email_approve" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('approve_email')">
                            <multi-selectbox v-model="formData.email_approve"
                                :multiple="false"
                                :options="trueOrFalseFormat"
                                :validate-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="phone_approve" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('approve_call')">
                            <multi-selectbox v-model="formData.phone_approve"
                                :multiple="false"
                                :options="trueOrFalseFormat"
                                :validate-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" lg="12" class="d-flex justify-content-end">
                    <b-button variant="primary" @click="saveForm">{{ $t('save') }}</b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>
<script>
// Template

// Components
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox"

//Pages

// Services
import AlumniService from '@/services/AlumniService';

// Others
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
    props: {
        communicationInfo: {
            type: Object,
            default: {}
        },
    },

    components: {
        MultiSelectbox,
        ValidationObserver,
        ValidationProvider,
    },

    data() {
        return {
            studentId: null,
            formData: {},
            trueOrFalseFormat: [
                {value: 'e', text: this.$t('yes')},
                {value: 'h', text: this.$t('no')}
            ],
        }
    },
    created() {
        this.setData()
        this.studentId = this.$route.params.id
    },
    methods: {
        setData(){
            this.formData = {
                sms_approve: this.communicationInfo.sms_approve,
                email_approve:  this.communicationInfo.email_approve,
                phone_approve: this.communicationInfo.phone_approve
            }
            this.formData.sms_approve = this.communicationInfo.sms_approve == true ? 'e' : 'h'
            this.formData.email_approve = this.communicationInfo.email_approve == true ? 'e' : 'h'
            this.formData.phone_approve = this.communicationInfo.phone_approve == true ? 'e' : 'h'
        },

        async saveForm(){
            const isValid = await this.$refs.formValidate.validate();
            if (isValid) {
                this.formData.sms_approve = this.formData.sms_approve == 'e' ? 1 : 0
                this.formData.email_approve = this.formData.email_approve == 'e' ? 1 : 0
                this.formData.phone_approve = this.formData.phone_approve == 'e' ? 1 : 0

                let formData = {...this.formData}
                
                AlumniService.communicationInfoUpdate(this.studentId, formData)
                .then(response => {
                    this.$emit('updateSuccess')
                    this.$toast.success(this.$t('api.' + response.data.message));
				}).catch(e => {
					this.showErrors(e, this.$refs.formModalValidate)
					}).finally(() => {
						this.formProcess = false
					})
            }
        },
    }
}
</script>

